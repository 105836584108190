<template>
  <div class="register_panel">
    <div  v-if="$store.state.isMaintaining == false" style="width: 100%">
      <div class="logo">
        <img src="../../assets/images/logo/ptn/logo01.png"/> 회원가입
      </div>
      <div class="field">
        <div class="n">아이디</div>
        <div class="ipt">
          <input type="text" placeholder="4-15자리 영문,숫자 조합" v-model="user.username"/>
        </div>
      </div>
      <div class="field">
        <div class="n">닉네임</div>
        <div class="ipt">
          <input v-model="user.nickname" type="text" placeholder="3-6자리 한글,영문">
        </div>
      </div>
      <div class="field">
        <div class="n">비밀번호</div>
        <div class="ipt">
          <input v-model="user.passwd" type="password" placeholder="비밀번호">
        </div>
      </div>
      <div class="field">
        <div class="n"> 비밀번호확인</div>
        <div class="ipt">
          <input v-model="user.confirm_password" type="password" placeholder="비밀번호확인">
        </div>
      </div>
      <div class="field">
        <div class="n">생일 년/월/일</div>
        <div class="ipt" style="justify-content: space-evenly">
          <div style="display: flex;align-items: center;justify-content: space-between;flex-wrap: nowrap">
            <select v-model="user.byear">
              <option :value="null">년도</option>
              <option :value="item" v-for="item in syear">{{item}}</option>
            </select>
            <select v-model="user.bmonth">
              <option :value="null">월</option>
              <option :value="item" v-for="item in smonth">{{item}}</option>
            </select>
            <select v-model="user.bday">
              <option :value="null">일</option>
              <option :value="item" v-for="item in sday">{{item}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="field">
        <div class="n">통신사</div>
        <div class="ipt" style="justify-content: space-evenly">
          <select v-model="user.telcomp">
            <option :value="null">- - - 통신사선택 - - -</option>
            <option value="SKT">SKT</option>
            <option value="LG">LG</option>
            <option value="KT">KT</option>
            <option value="알뜰SKT">알뜰SKT</option>
            <option value="알뜰LG">알뜰LG</option>
            <option value="알뜰KT">알뜰KT</option>
          </select>
        </div>
      </div>
      <div class="field">
        <div class="n">휴대폰</div>
        <div class="ipt">
          <input v-model="user.phone" type="text" placeholder="`-` 없이 숫자만 입력">
        </div>
      </div>
      <div class="field">
        <div class="n">인증코드</div>
        <div class="ipt">
          <input v-model="user.verificationCode" type="text" placeholder="휴대폰 인증코드">
          <div class="btn-recivehpnecode" @click="getVerifyPhoneCode">인증받기</div>
        </div>

      </div>
      <div class="field">
        <div class="n">은행선택</div>
        <div class="ipt" style="justify-content: space-evenly">
          <select v-model="user.bank" class="select">
            <option value="">- - - 은행선택 - - -</option>
            <option value="카카오뱅크">카카오뱅크</option>
            <option value="신한은행">신한은행</option>
            <option value="국민은행">국민은행</option>
            <option value="단위농협">단위농협</option>
            <option value="농협중앙회">농협중앙회</option>
            <option value="우리은행">우리은행</option>
            <option value="하나은행">하나은행</option>
            <option value="SC제일은행">SC제일은행</option>
            <option value="기업은행">기업은행</option>
            <option value="우체국">우체국</option>
            <option value="경남은행">경남은행</option>
            <option value="광주은행">광주은행</option>
            <option value="대구은행">대구은행</option>
            <option value="부산은행">부산은행</option>
            <option value="산림조합">산림조합</option>
            <option value="산업은행">산업은행</option>
            <option value="수협은행">수협은행</option>
            <option value="신협은행">신협은행</option>
            <option value="상호저축은행">상호저축은행</option>
            <option value="전북은행">전북은행</option>
            <option value="제주은행">제주은행</option>
            <option value="씨티은행">씨티은행</option>
            <option value="새마을금고">새마을금고</option>
            <option value="신한금융투자">신한금융투자</option>
            <option value="NH농협투자증권">NH농협투자증권</option>
            <option value="카카오증권">카카오증권</option>
            <option value="SBI저축은행">SBI저축은행</option>
            <option value="케이뱅크">케이뱅크</option>
            <option value="토스뱅크">토스뱅크</option>
          </select>
        </div>
      </div>
      <div class="field">
        <div class="n">계좌번호</div>
        <div class="ipt">
          <input v-model="user.acNo" type="text" placeholder="`-` 없이 숫자만 입력">
        </div>
      </div>
      <div class="field">
        <div class="n">예금주</div>
        <div class="ipt">
          <input v-model="user.beneficiary" type="text" placeholder="환전 예금주명">
        </div>
      </div>
      <div class="field">
        <div class="n">추천코드</div>
        <div class="ipt">
          <input type="text" v-model="user.code"  placeholder="총판,추천인 코드">
        </div>
      </div>
      <div class="error-message" v-show="erm != null">
        <i class="fa fa-warning"></i> {{ erm }}
      </div>

      <div class="field">
        <button type="button" class="regbutton" @click="doRegister">가입하기</button>
      </div>

      <div class="login-panel-close" @click="closePanel">
        <img src="../../assets/images/icon/common/close1.svg"/>
      </div>
    </div>
    <div v-html="$store.state.maintainingMessage" v-if="$store.state.isMaintaining == true">

    </div>
  </div>


</template>

<script>
    import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../../store/mutation-types";
    import {getVerificationCode, register} from "@/network/userRequest";
    export default {
        name: "MemberRegisterSection",
        components: {
        },
        data() {
            return {
              user: {
                username: '',
                passwd: '',
                confirm_password: '',
                nickname: '',
                phone: '',
                verificationCode: '',
                bank: '',
                beneficiary: '',
                acNo: '',
                extractPasswd: '',
                code: this.$store.state.userinsertagentcode,
                telcomp: null,
                byear: null,
                bmonth: null,
                bday: null,
              },
                isShowMessage: false,
                content: '',
                errmessage: null,
                imageSrc: null,
              isUsePhoneCode: false,
              erm: null,
              syear: [],
              smonth: [],
              sday: [],
            }
        },
        methods: {
            closePanel() {
              this.$store.state.isShowLoginPanel = false;
              this.$store.state.isShowRegisterPanel = false;
            },
          doRegister() {
            if (!this.checkConfirmPassword()) {
              this.erm = '입력하신 비밀번호가 동일하지 않습니다'
              return false;
            }
            this.$store.commit(RECEIVE_SHOW_LOADING)
            register(this.user).then(res => {
              if (res.data.success) {
                this.$swal({
                  title: '회원가입을 축하드립니다.관리자 확인후 이용가능합니다',
                  type: 'success',
                  showCancelButton: false,
                  showConfirmButton: true
                })
                this.erm = null
                this.$router.replace('/main')
              } else {
                // this.$swal({
                //     title: res.data.msg,
                //     type: 'error',
                //     showCancelButton: false,
                //     showConfirmButton: true
                // })
                this.erm = res.data.msg
              }
              this.$store.commit(RECEIVE_HIDE_LOADING)
            })
          },
          getVerifyPhoneCode() {
            if (this.checkPhone()) {
              this.$store.commit(RECEIVE_SHOW_LOADING)
              getVerificationCode(this.user.phone).then(res => {
                this.$store.commit(RECEIVE_HIDE_LOADING)
                if (res.data.success) {
                  this.$swal({
                    title: '인증코드 전송되였습니다. 휴대폰 문자를 확인해주세요',
                    type: 'success',
                    showCancelButton: false,
                    showConfirmButton: true
                  })
                } else {
                  this.erm = res.data.msg
                }
              })
            }
          },
          checkConfirmPassword() {
            return !(this.user.confirm_password.trim() === '' || this.user.passwd.trim() !== this.user.confirm_password.trim());
          },
          checkPhone() {
            if (!/^[0-9]{11,12}$/.test(this.user.phone)) {
              this.erm = '휴대폰 번호를 정확하게 입력하세요'
              return false;
            }
            return true;
          },
        },
        created() {
          let b = 1949
          for (let i = 1; i <= 71; i++) {
            let bb = b + i;
            this.syear.push(bb)
          }
          for (let i = 1; i <= 12; i++) {
            this.smonth.push(i)
          }
          for (let i = 1; i <= 31; i++) {
            this.sday.push(i)
          }
        },
        computed: {

        }
    }
</script>

<style scoped>
    .register_panel {
      width: 40rem;
      max-height: 65rem;
      background-color: rgb(23, 22, 22);
      color: #fff9ff;
      position: relative;
      border: .2rem solid;
      border-image: linear-gradient(#f8d630, #f1f1f1);
      border-image-slice: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 5rem 2rem;
      overflow-x: hidden;
      overflow-y: scroll ;
    }
    .register_panel .logo{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
    }
    .register_panel .logo img{
      width: 10rem;
    }
    .register_panel .field {
      width: 100%;
      position: relative;
      margin: 1rem 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: nowrap;
    }
    .register_panel .field .n{
      width: 30%;
      color: #a9a9a9;
    }
    .register_panel .field .ipt{
      width: 70%;
      color: #fff9ff;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: nowrap;
      position: relative;
    }
    .register_panel .field input {
      width: 95%;
      background-color: #212123;
      color: #fff9ff;
      border-radius: 5rem;
      border: 1px solid #626262;

      font-size: 1.2rem;
      height: 3rem;
      outline: 0;
      padding: 0;
      text-align: center;
    }

    .register_panel .field button{
      width: 100%;
      background-color: #212123;
      border: 0;
      border-radius: 5rem;
      color: #fff9ff;
      font-size: 1.2rem;
      height: 4.4rem;
      outline: 0;
      padding: 0;
      text-align: center;
      cursor: pointer;
    }

    .register_panel .field .regbutton{
      background: linear-gradient(to right, #f8ff42, #ff0073);

    }

    .register_panel .field .regbutton:hover{
      background: linear-gradient(to top, #f8ff42, #ff0073);
    }

    .register_panel .login-panel-close {
      position: absolute;
      top: 1rem;
      right: 1rem;
      width: 3rem;
      height: 3rem;
      padding: .2rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 1px;
      font-weight: normal;
    }
    .register_panel img {
       width: 3rem;
    }

    .btn-recivehpnecode{
      position: absolute;
      right: .2rem;
      top: .3rem;
      width: 6rem!important;
      line-height: 2.2rem!important;
      background: linear-gradient(180deg, #d0b059, #eecd75);
      color: #353535;
      font-size: 1rem;
      font-weight: bold;
      border-radius: 5rem;
      text-align: center;
      cursor: pointer;
    }


    @media screen and (max-width: 2560px) {

    }

    @media screen and (max-width: 1920px) {

    }

    @media screen and (max-width: 1024px) {
      .register_panel {
        width: 95% !important;
      }
    }

    .error-message {
      width: 100%;
      text-align: center;
      font-size: 1.4rem;
      box-sizing: border-box;
      color: #f61515;
      animation: sansan 1200ms infinite;
      -webkit-animation: sansan 1200ms infinite;
      margin-top: 1rem;
    }
    .error-message {
      position: relative!important;
    }


    select {
      background-color: #212123;
      color: #fff9ff;
      padding-right: 1.4rem;
      border-radius: 5rem;
      height: 3rem;
      text-align: center;
    }



</style>
