<template>
  <div class="register_content" :class="{'showLoginPannel':$store.state.isShowRegisterPanel && !$store.state.isLogin}">
    <member-register-section></member-register-section>
  </div>
</template>

<script>
    import {loginRegisterMixin} from "../../common/mixin";
    import WarningSample from "../../components/WarningSample";
    import MemberLoginSection from "@/views/afront/MemberLoginSection.vue";
    import MemberRegisterSection from "@/views/afront/MemberRegisterSection.vue";

    export default {
        name: "MemberRegisterWindow",
        components: {
          MemberRegisterSection
        },
        data() {
            return {
                user: {
                    username: '',
                    passwd: '',
                    hvcode: null,
                },
            }
        },
        methods: {


        },
        created() {
        },
        computed: {

        }
    }
</script>

<style scoped>
    .register_content {
      width: 100%;
      height: 100vh;
      position: fixed;
      right: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1000;
      transform: translateY(-100%);
      transition: 0.3s transform;
      background-color: rgba(0, 0, 0, .7);
    }
    .showLoginPannel {
      transform: translateY(0%);
    }


</style>
